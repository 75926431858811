




































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Axios from 'axios'
import { RedCapital, SelectedCountry, Configuration, TitleTemplatePublic } from '@/configuration'
import API from '@/api'
import ReusableButton from '@/components/ReusableButton.vue'
import DniField from '@/components/DniField.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import CurrencyInput from '@/components/Currency.vue'

@Component({
  metaInfo: {
    title: 'Informar pago',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  computed: {
    ...mapState(['transactionProjectId', 'transactionTransference'])
  },
  data() {
    return {
      btn: {
        clickable: false,
        loading: false,
        isOk: null,
        successText: 'Contraseña actualizada',
        errorText: 'Error al actualizar la contraseña',
      },
      transactionCompleted: false,
      transaction: null,
      project: null,
      form: {
        paymentType: null,
        amount: 0,
        bankAccount: '123123123',
        name: 'Hector Segura',
        ruc: '12345678901',
        bank: null,
      },
      banks: null,
      ok: false,
      paymentTypes: [
        {
          id: 0,
          text: 'Solo reserva',
          disabled: false,
        },
        {
          id: 1,
          text: 'Monto total',
          disabled: false,
        },
      ],
      rules: {
        name: [
          (v: any) => !!v || 'Nombre es requerido',
        ],
        bankAccount: [
          (v: any) => !!v || 'Numero de cuenta es requerido',
        ],
        bank: [
          (v: any) => !!v || 'Banco es requerido',
        ],
        paymentType: [
          (v: any) => !!v || 'Tipo de pago es requerido',
        ],
      },
    }
  },
  watch: {
    form: {
      handler(val: any) {
        // @ts-ignore
        this.validateForm()
      },
      deep: true,
    },
    ok(val) {
      // @ts-ignore
      this.validateForm()
    },
    'form.paymentType'(val: number) {
      if (val == 0) {
        this.$data.form.amount = this.$store.state.transactionTransference.monto
      } else {
        this.$data.form.amount = this.$store.state.transactionTransference.monto + this.$store.state.transactionTransference.inversion
      }
    },
  },
  created() {
    if (this.$store.state.transactionTransference.reserva) {
      this.$data.form.paymentType = 0
      this.$data.form.amount = this.$store.state.transactionTransference.monto
    } else {
      this.$data.form.paymentType = 1
      this.$data.form.amount = this.$store.state.transactionTransference.monto + this.$store.state.transactionTransference.inversion
    }
  },
  async beforeMount() {
    this.$data.banks = await API.getBanks()
    this.$data.form.bank = this.$data.banks[0].id
  },
  mounted() {
    setTimeout(() => {
      // @ts-ignore
      this.validateForm()
    }, 1000)
  },
  methods: {
    confirmPayment() {
      let payload = {
        cod: this.$store.state.transactionTransference.cod,
        id: this.$store.state.transactionTransference.id,
        bank: this.$data.form.bank,
        bankAccount: this.$data.form.bankAccount,
        name: this.$data.form.name,
        rut: this.$data.form.ruc,
        date: moment(Date.now()).format('YYYY-MM-DD'),
      }

      API.payment.report.transference(this.$store.state.transactionProjectId, this.$data.form.amount, payload).then( (res: any) => {
        console.log(res)
      }).catch( error => {
        console.log(error)
      }).finally( () => {
        this.$data.transactionCompleted = true
      })
    },
    validateForm() {
      if (this.$refs.reportPayment != null) {
        // @ts-ignore
        this.$data.btn.clickable = (this.$refs.reportPayment.validate() && this.$data.ok)
      } else {
        this.$data.btn.clickable = false
      }
    },
  },
  components: {
    DniField,
    ReusableButton,
    CurrencyInput,
  },
})
export default class PaymentInfo extends Vue {}
